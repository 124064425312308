import { Environment, PLAID_SETTINGS } from './environment.model';

export const environment: Environment = {
  production: false,
  apiBaseUrl: 'https://admin-api-qa.fundo.com/api/v1',
  plaidSettings: {
    plaidPublicKey: PLAID_SETTINGS.plaidPublicKey,
    env: 'sandbox',
    clientName: PLAID_SETTINGS.clientName,
    product: PLAID_SETTINGS.product,
  },
  grafanaFaroSettings: {
    url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/e9e15effc4ec9fa47a0a508f099da19a',
    app: {
      name: 'AdminQA',
      environment: 'qa',
    },
  },
  captchaKey: '6Lf5MsMZAAAAAE5JqQ9SXWr8jximjOr7L4cCEW1T',
  googleId: 'UA-157340971-1',
};
